import { action, autorun, computed, decorate, extendObservable } from "mobx";
import _ from "lodash";
import queryString from "query-string";
import { formatAllotment } from "../../utilities/comptixFormatter";
import moment from "moment/moment";

class UserAdminStore {
  constructor(authStore, commonStore, compTixApi, routerStore, resetPasswordStore, loadingStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.resetPasswordStore = resetPasswordStore;
    this.loadingStore = loadingStore;

    this.defaults = {
      departments: [],
      users: [],
      userIdsToUpdateAllotment: [],
      selectedDepartment: { value: -1, label: "All" },
      selectedSeason: { value: -1, label: "" },
      sortFilters: {
        direction: "ASC",
        key: "name"
      },
      searchTerm: "",
      season: null,
      showChangePassModal: false,
      selectedActiveness: { value: 1, label: "Active" }
    };

    extendObservable(this, {
      users: this.defaults["users"],
      departments: this.defaults["departments"],
      selectedDepartment: this.defaults["selectedDepartment"],
      selectedSeason: this.defaults["selectedSeason"],
      searchTerm: this.defaults["searchTerm"],
      sortFilters: this.defaults["sortFilters"],
      showChangePassModal: this.defaults["showChangePassModal"],
      season: this.defaults["season"],
      userIdsToUpdateAllotment: this.defaults["userIdsToUpdateAllotment"],
      selectedActiveness: this.defaults["selectedActiveness"],
      setDepartments: action(value => {
        this.departments = value;
      }),
      setDepartment: action(value => {
        this.selectedDepartment = value;
      }),
      setUsers: action(value => {
        this.users = value;
      }),
      setPasswordModalShow: action(value => {
        this.resetPasswordStore.setShowResetPassword(value);
      }),
      setSeason: action(season => {
        this.selectedSeason = season;
        this.commonStore.setUserAdminSeason(this.commonStore.seasons.find(s => s.seasonId === season.value));
      }),
      setSearchTerm: action(value => {
        this.searchTerm = value;
      }),
      clearSearchTerm: action(() => {
        this.searchTerm = "";
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      openChangePassword: action(value => {
        this.resetPasswordStore.setSelectedUserId(value);
        this.resetPasswordStore.clearPasswordForm();
        this.setPasswordModalShow(true);
      }),
      cancelResetPassword: action(() => {
        this.resetPasswordStore.clearPasswordForm();
        this.setPasswordModalShow(false);
      }),
      createNewUser: action(() => {
        let season = this.commonStore.seasons.find(s => s.seasonId === this.getSeason.value);
        let to = season ? "/admin/user/edit/-1?season=" + season.seasonId : "/admin/user/edit/-1";
        this.commonStore.setUserAdminSeason(season);
        this.routerStore.history.push(to);
      }),
      updateFromUrlParams: action(search => {
        const params = queryString.parse(search);
        if (params["season"]) {
          this.season = params["season"] * 1;
        }
      }),
      resetStore: action(() => {
        this.users = this.defaults["users"];
        this.season = this.defaults["season"];
        this.selectedSeason = this.defaults["selectedSeason"];
        this.sortFilters = this.defaults["sortFilters"];
      }),
      addUserstoUpdateAllotment: action(rows => {
        rows.forEach(row => {
          this.userIdsToUpdateAllotment.push(row.userId);
        });
      }),
      removeUserstoUpdateAllotment: action(rows => {
        let userIdsToRemove = rows.map(row => row.userId);
        this.userIdsToUpdateAllotment = _.difference(this.userIdsToUpdateAllotment, userIdsToRemove);
      }),
      resetUserToUpdateAllotment: action(() => {
        this.userIdsToUpdateAllotment = [];
      }),
      setSelectedActiveness: action(activeness => {
        this.selectedActiveness = activeness;
      })
    });

    autorun(() => {
      if (this.routerStore.isUserAdminTab && this.commonStore.currentOrgId) {
        this.updateFromUrlParams(this.routerStore.location.search);
        this.updatePageOnOrgChange(this.commonStore.currentOrgId);
        this.userIdsToUpdateAllotment = this.defaults["userIdsToUpdateAllotment"];
      } else {
        this.resetStore();
      }
    });

    autorun(() => {
      if (this.routerStore.isUserAdminTab && this.season && this.commonStore.seasonDropdownList.length) {
        let value = this.commonStore.seasonDropdownList.find(sdl => sdl.value === this.season);
        if (value) {
          this.setSeason(value);
        }
      }
    });
  }

  get departmentsDropdownList() {
    let departmentsInOrg = this.departments.map(department => {
      return {
        value: department.departmentId,
        label: department.departmentType.departmentName
      };
    });
    departmentsInOrg.unshift(this.defaults["selectedDepartment"]);
    return departmentsInOrg;
  }

  get activenessDropdownList() {
    return [{ value: 1, label: "Active" }, { value: 0, label: "Inactive" }, { value: -1, label: "All" }];
  }

  get getSeason() {
    if (this.selectedSeason.value === -1 && this.commonStore.activeSeasonDropdownList.length > 0) {
      let season = this.commonStore.activeSeasonDropdownList.filter(
        season => season.value === this.commonStore.curSeason.seasonId
      )[0];
      return season ? season : this.commonStore.activeSeasonDropdownList[0];
    } else {
      return this.selectedSeason;
    }
  }

  get displayedUsers() {
    let season = this.commonStore.seasons.find(s => s.seasonId === this.getSeason.value);
    let toDisplay = this.users.map(user => {
      let editLink = season
        ? "/admin/user/edit/" + user.userId + "?season=" + season.seasonId
        : "/admin/user/edit/" + user.userId;
      return {
        isSelected: _.includes(this.userIdsToUpdateAllotment, user.userId),
        editLink: editLink,
        userId: user.userId,
        userId1: user.userId,
        name: user.lastName + ", " + user.firstName,
        lastName: user.lastName,
        firstName: user.firstName,
        email: user.email,
        username: user.username,
        department: user.department.departmentType.departmentName,
        // userRole: user.role.roleName,
        userRoles: user.roles.map(role => role.roleName).join(", "),
        statsId: user.statsId === 0 ? null : user.statsId,
        manuallyManaged: !!user.manuallyManaged,
        homeAllotment: formatAllotment(
          user.userAllotments,
          this.getSeason,
          "homeFamilyAllotment",
          "homeFriendsAllotment"
        ),
        awayAllotment: formatAllotment(
          user.userAllotments,
          this.getSeason,
          "awayFamilyAllotment",
          "awayFriendsAllotment"
        ),
        seasonId: this.getSeason,
        departmentId: user.department.departmentId,
        active: user.active
      };
    });
    toDisplay = this.filterByDepartment(toDisplay, this.selectedDepartment);
    toDisplay = this.filterBySearchTerm(toDisplay, this.searchTerm);
    toDisplay = this.filterByActiveness(toDisplay, this.selectedActiveness);
    return this.sort(toDisplay, this.sortFilters);
  }

  get lastCommaFirst() {
    return this.selectedUser ? this.selectedUser.lastName + ", " + this.selectedUser.firstName : "";
  }

  get selectedUser() {
    if (this.resetPasswordStore.selectedUserId === -1) {
      return null;
    } else {
      return _.find(this.users, { userId: this.resetPasswordStore.selectedUserId });
    }
  }

  buildUserExport() {
    let season = this.commonStore.seasons.find(s => s.seasonId === this.getSeason.value);
    this.loadingStore.setLoading(true);
    this.compTixApi
      .sendUserExport(
        this.commonStore.currentOrgId,
        season.year,
        season.seasonType.seasonTypeId,
        this.sortFilters["key"],
        this.sortFilters["direction"],
        this.searchTerm,
        this.selectedDepartment.label,
        this.selectedActiveness.value === 1 || this.selectedActiveness.value === -1,
        this.selectedActiveness.value === 0 || this.selectedActiveness.value === -1
      )
      .then(response => {
        this.downloadLink(response, "application/vnd.ms-excel");
      })
      .finally(fin => {
        this.loadingStore.setLoading(false);
      });
  }

  downloadLink(response, type) {
    let blob = new Blob([response.data], { type: type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, response.headers["report-title"]);
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", response.headers["report-title"]);
    document.body.appendChild(link);
    link.click();
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  updatePageOnOrgChange(newOrgId) {
    if (newOrgId !== -1) {
      this.compTixApi.getDepartmentsByOrg(newOrgId, false).then(data => {
        if (data) {
          this.setDepartments(data);
          let filter = data.filter(d => d.departmentType.departmentName === this.selectedDepartment.label);
          if (filter.length) {
            this.setDepartment({ value: filter[0].departmentId, label: filter[0].departmentType.departmentName });
          }
        }
      });
      this.compTixApi.getUsersByOrg(newOrgId, false, true).then(data => {
        if (data) {
          this.setUsers(data);
        }
      });
    }
  }

  updateUsers(orgId) {
    if (orgId !== -1) {
      this.compTixApi.getUsersByOrg(orgId, false, true).then(data => {
        if (data) {
          this.setUsers(data);
        }
      });
    }
  }

  filterByDepartment(users, selectedDepartment) {
    if (selectedDepartment.value === -1) {
      return users;
    } else {
      return _.filter(users, user => {
        return user.department === selectedDepartment.label;
      });
    }
  }

  filterBySearchTerm(users, searchTerm) {
    if (searchTerm.length <= 0) {
      return users;
    } else {
      return _.filter(users, user => {
        let search = _.lowerCase(searchTerm);
        return (
          _.includes(_.lowerCase(user.name), search) ||
          _.includes(_.lowerCase(user.department), search) ||
          _.includes(_.lowerCase(user.email), search) ||
          _.includes(_.lowerCase(user.username), search) ||
          _.includes(_.lowerCase(user.userRoles), search) ||
          _.includes(_.lowerCase(user.homeAllotment), search) ||
          _.includes(_.lowerCase(user.awayAllotment), search) ||
          _.includes(_.lowerCase(user.statsId), search)
        );
      });
    }
  }

  filterByActiveness(users, activeness) {
    if (activeness.value === -1) {
      return users;
    } else if (activeness.value === 1) {
      return _.filter(users, user => user.active);
    } else {
      return _.filter(users, user => !user.active);
    }
  }

  sort(users, searchFilters) {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(users, [user => user["lastName"].toLowerCase(), user => user["firstName"].toLowerCase()]);
    } else if (direction === "ASC") {
      if (searchFilters.key === "name") {
        return _.sortBy(users, [user => user["lastName"].toLowerCase(), user => user["firstName"].toLowerCase()]);
      }
      return _.sortBy(users, [user => _.toLower(user[searchFilters.key])]);
    } else if (direction === "DESC") {
      if (searchFilters.key === "name") {
        return _.sortBy(users, [
          user => user["lastName"].toLowerCase(),
          user => user["firstName"].toLowerCase()
        ]).reverse();
      }
      return _.sortBy(users, [user => _.toLower(user[searchFilters.key])]).reverse();
    } else {
      return users;
    }
  }
}

decorate(UserAdminStore, {
  activenessDropdownList: computed,
  departmentsDropdownList: computed,
  getSeason: computed,
  selectedUser: computed,
  displayedUsers: computed,
  lastCommaFirst: computed
});

export default UserAdminStore;
