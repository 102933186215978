import React from "react";
import { Accordion } from "best-common-react";
import { inject, observer } from "mobx-react";

class UmpireAssignmentAccordion extends React.Component {
  render() {
    return (
      <div>
        <Accordion>
          <Accordion.Header isToggle={false}>
            {this.props.data.lastName}, {this.props.data.useName} - {this.props.data.positionCode}
          </Accordion.Header>
        </Accordion>
      </div>
    );
  }
}

export default inject("rootStore")(observer(UmpireAssignmentAccordion));
