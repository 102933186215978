import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { AccordionTableWrapper, TableTitle } from "best-common-react";
import UmpireAssignmentAccordion from "./UmpireAssignmentAccordion";

const cols = [
  { key: "lastName", name: "Last Name" },
  { key: "useName", name: "First Name" },
  { key: "positionCode", name: "Position" }
];

class UmpireAssignmentTable extends React.Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <TableTitle>Umpire Assignments</TableTitle>
            <AccordionTableWrapper
              data={this.props.umpires}
              columns={cols}
              height={this.props.umpires.length * 28.5 + 75}
              minWidth={1000}
              accordion={UmpireAssignmentAccordion}
            />
          </div>
        </div>
      </div>
    );
  }
}

UmpireAssignmentTable.propTypes = {
  umpires: PropTypes.any
};
UmpireAssignmentTable.defaultProps = {
  umpires: []
};

export default inject("rootStore")(observer(UmpireAssignmentTable));
