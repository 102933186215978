import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import UserFilters from "../../components/user/admin/UserFilters";
import UserTable from "../../components/user/admin/UserTable";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import UserResetPasswordModal from "../../components/user/admin/UserResetPasswordModal";
import UserAdminFooter from "../../components/user/admin/UserAdminFooter";
import AdminProtected from "../../components/protected/AdminProtected";
import UserUpdateTicketAllotmentModal from "./UserUpdateTicketAllotmentModal";
import LoadingSpinner from "../../components/loading/LoadingSpinner";

class UserAdminContainer extends React.Component {
  render() {
    return (
      <div>
        <TeamTitle />
        <LoadingSpinner />
        <CardContainer>
          <CardHeader>Users</CardHeader>
          <CardBody>
            <UserUpdateTicketAllotmentModal />
            <UserResetPasswordModal />
            <UserFilters />
            <UserTable />
          </CardBody>
          <CardFooter>
            <UserAdminFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default AdminProtected(UserAdminContainer);
