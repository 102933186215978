import React from "react";
import TeamTitle from "../../components/title/TeamTitle";
import EventDetailsFilters from "../../components/events/admin/details/EventDetailsFilters";
import EventTicketAccessTable from "../../components/events/admin/details/EventTicketAccessTable";
import EventDetailsAccessOverrideModal from "../../components/events/admin/details/EventDetailsAccessOverrideModal";
import { CardBody, CardContainer, CardHeader, CardFooter } from "best-common-react";
import SectionsTable from "../../components/section/SectionsTable";
import { inject, observer } from "mobx-react";
import DeleteSectionOverrideModal from "../../components/section/DeleteSectionOverrideModal";
import EditSectionOverrideModal from "../../components/section/SectionInventoryOverrideModal";
import DeleteTicketAccessOverrideModal from "../../components/events/admin/details/DeleteTicketAccessOverrideModal";
import EventDetailsFooter from "../../components/events/admin/details/EventDetailsFooter";
import EventDetailsBulkAccessOverrideModal from "../../components/events/admin/details/EventDetailsBulkAccessOverrideModal";
import MessagesTable from "../../components/messages/MessagesTable";
import DeleteMessageModal from "../../components/messages/DeleteMessageModal";
import AddMessageModal from "../../components/messages/AddMessageModal";
import UnlockGameModal from "../../components/events/admin/details/UnlockGameModal";
import LockGameModal from "../../components/events/admin/details/LockGameModal";
import AdminOrBoxOfficeProtected from "../../components/protected/AdminOrBoxOfficeProtected";
import UmpireAssignmentTable from "../../components/messages/UmpireAssignmentTable";

class AdminEventDetailsContainer extends React.Component {
  render() {
    const { eventDetailsStore, authStore, commonStore } = this.props.rootStore;
    return (
      <div>
        <TeamTitle disabled={true} />
        <CardContainer>
          <CardHeader>Event Details</CardHeader>
          <CardBody>
            <EventDetailsAccessOverrideModal />
            <EventDetailsBulkAccessOverrideModal />
            <DeleteTicketAccessOverrideModal show={eventDetailsStore.showEventAccessDeleteModal} />
            <DeleteSectionOverrideModal show={eventDetailsStore.showSectionDeleteModal} />
            <EditSectionOverrideModal show={eventDetailsStore.showEditSectionOverrideModal} />
            <EventDetailsFilters />
            {authStore.isGlobal ? <EventTicketAccessTable /> : null}
            <SectionsTable
              isGlobal={authStore.isGlobal}
              isAdmin={authStore.isAdmin}
              isBoxOfficeAdmin={authStore.isBoxOfficeAdmin}
              isHome={eventDetailsStore.isHome}
              sections={eventDetailsStore.sectionsAndInventoryOverrides}
              setSortDirection={eventDetailsStore.inventoryGridStore.setSortDirection}
              sortColumn={eventDetailsStore.inventoryGridStore.sortFilters["key"]}
              sortDirection={eventDetailsStore.inventoryGridStore.sortFilters["direction"]}
            />
            <div>
              <UnlockGameModal
                show={eventDetailsStore.showUnlockGameModal}
                onClose={() => {
                  eventDetailsStore.setShowUnlockGameModal(false);
                }}
                onUnlock={() => {
                  eventDetailsStore.unlockGame(eventDetailsStore.event.gamePk, commonStore.currentOrg.statsId);
                }}
              />
              <LockGameModal
                show={eventDetailsStore.showLockGameModal}
                onClose={() => {
                  eventDetailsStore.setShowLockGameModal(false);
                }}
                onLock={() => {
                  eventDetailsStore.lockGame(eventDetailsStore.event.gamePk, commonStore.currentOrg.statsId);
                }}
              />
              <DeleteMessageModal
                show={eventDetailsStore.showDeleteMessageModal}
                onClose={() => eventDetailsStore.setShowDeleteMessageModal(false)}
                onDelete={() => eventDetailsStore.deleteEventMessage(eventDetailsStore.messageToDelete)}
              />
              <AddMessageModal
                show={eventDetailsStore.showAddMessageModal}
                onClose={() => eventDetailsStore.setShowAddMessageModal(false)}
                onChange={comment => eventDetailsStore.setCurrentMessage(comment)}
                onSave={eventDetailsStore.saveEventMessage}
                updateDepartments={eventDetailsStore.setEventMessageDepartments}
                departmentOptions={eventDetailsStore.departmentDropdown}
                message={eventDetailsStore.currentEditingEventMessage}
                isSaving={eventDetailsStore.isSaving}
              />
              {authStore.isGlobal ? (
                <MessagesTable
                  isGlobal={authStore.isGlobal}
                  onClick={() => eventDetailsStore.setShowAddMessageModal(true)}
                  messages={eventDetailsStore.eventMessages}
                />
              ) : null}
              <UmpireAssignmentTable umpires={eventDetailsStore.umpires} />
            </div>
          </CardBody>
          <CardFooter>
            <EventDetailsFooter />
          </CardFooter>
        </CardContainer>
      </div>
    );
  }
}

export default inject("rootStore")(AdminOrBoxOfficeProtected(observer(AdminEventDetailsContainer)));
